import * as React from "react"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import useAuthenticationCheck from "../hooks/useAuthenticationCheck"
import { useUser } from "../context/UserContext"
import { useToken } from "../context/UseToken"
import colors from "../styles/colors"
import PageHeading from "../components/PageHeading"

const AdminPage: React.FC = () => {
  useAuthenticationCheck()
  const { user: currentActiveUser } = useUser()
  const { getToken } = useToken()

  type State = {
    organizations: any[]
    users: any[]
    rulesets: any[]
    loading: boolean
    error: string | null
  }

  const [state, setState] = useState<State>({
    organizations: [],
    users: [],
    rulesets: [],
    loading: false,
    error: null,
  })

  const roles = ["USER", "ORGANIZATION_ADMIN", "SUPER_ADMIN"]

  const tableConfig = [
    { key: "id", name: "ID", type: "text" },
    { key: "createdAt", name: "Created At", type: "text" },
    { key: "email", name: "Email", type: "text" },
    { key: "auth0Id", name: "Auth0 ID", type: "text" },
    { key: "stripeId", name: "Stripe ID", type: "text" },
    { key: "isPremium", name: "Is Premium", type: "checkbox" },
    {
      key: "organizationId",
      name: "Organization",
      type: "dropdown",
      dynamicOptions: "organizations",
    },
    { key: "role", name: "Role", type: "dropdown", options: roles },
  ]

  const fetchData = async (endpoint: string) => {
    try {
      const token = await getToken()
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/${endpoint}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return await response.json()
    } catch (error) {
      console.error(`Error fetching ${endpoint}`, error)
    }
  }

  useEffect(() => {
    const fetchAndSetData = async () => {
      setState({ ...state, loading: true })

      if (currentActiveUser) {
        if (currentActiveUser.role !== "SUPER_ADMIN") {
          setState(prevState => ({
            ...prevState,
            loading: false,
            error: "You do not have permission to access this page.",
          }))
          return
        }
        const organizations = await fetchData("organizations")
        const users = await fetchData("users")
        const rulesets = await fetchData("rules/rulesets")

        setState(prevState => ({
          ...prevState,
          organizations,
          users,
          rulesets,
          loading: false,
        }))
      }
    }

    fetchAndSetData()
  }, [currentActiveUser])

  useEffect(() => {
    console.log("Users updated:", state.users)
  }, [state.users]) // This useEffect will run whenever `state.users` changes

  async function updateUserValue(user, columnKey, newValue) {
    console.log(
      `Value for user ${user.id} in column ${columnKey} changed to ${newValue}`
    )
    if (columnKey === "organizationId") {
      // Turn newvalue to an integer
      newValue = parseInt(newValue)
    }
    try {
      const token = await getToken()
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/user?id=${user.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            data: {
              [columnKey]: newValue,
            },
          }),
        }
      )

      if (response.ok) {
        if (response.ok) {
          // Update the user state
          const updatedUsers = state.users.map(u =>
            u.id === user.id ? { ...u, [columnKey]: newValue } : u
          )
          await setState(prevState => ({ ...prevState, users: updatedUsers }))
        }
      }
    } catch (error) {
      console.error(`Error updating user: `, error)
    }
  }

  async function updateOrganizationRulesets(
    organizationId,
    rulesetId,
    newValue
  ) {
    console.log(
      `Value for organization ${organizationId} in ruleset ${rulesetId} changed to ${newValue}`
    )

    const method = newValue ? "POST" : "DELETE"
    const body = JSON.stringify({
      organizationId: organizationId,
      rulesetId: rulesetId,
    })

    try {
      const token = await getToken()
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/organizationRuleset`,
        {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: body,
        }
      )

      if (!response.ok) {
        const errorData = await response.json()
        console.error("Error:", errorData)
      } else {
        console.log("Request was successful")
        // Update the ruleset state
        const updatedRulesets = state.rulesets.map(r =>
          r.id === rulesetId
            ? {
                ...r,
                organizations: newValue
                  ? [...r.organizations, { organizationId }]
                  : r.organizations.filter(
                      rOrg => rOrg.organizationId !== organizationId
                    ),
              }
            : r
        )
        await setState(prevState => ({
          ...prevState,
          rulesets: updatedRulesets,
        }))
      }
    } catch (error) {
      console.error("An error occurred:", error)
    }
  }

  const [newOrgName, setNewOrgName] = useState("")
  const [editingOrgId, setEditingOrgId] = useState(null)
  const [editedOrgName, setEditedOrgName] = useState("")

  async function updateOrganization(organizationId, newName) {
    console.log(`Name for organization ${organizationId} changed to ${newName}`)
    try {
      const token = await getToken()
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/organization?id=${organizationId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: newName,
          }),
        }
      )

      if (response.ok) {
        console.log("Request was successful")
        // Update the organization state
        const updatedOrganizations = state.organizations.map(o =>
          o.id === organizationId ? { ...o, name: newName } : o
        )
        await setState(prevState => ({
          ...prevState,
          organizations: updatedOrganizations,
        }))
      } else {
        const errorData = await response.json()
        console.error("Error:", errorData)
      }
    } catch (error) {
      console.error("An error occurred:", error)
    }
  }

  async function deleteOrganization(organizationId) {
    console.log(`Organization ${organizationId} deleted`)
    try {
      const token = await getToken()
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/organization?id=${organizationId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.ok) {
        console.log("Request was successful")
        // Update the organization state
        const updatedOrganizations = state.organizations.filter(
          o => o.id !== organizationId
        )
        await setState(prevState => ({
          ...prevState,
          organizations: updatedOrganizations,
        }))
      } else {
        const errorData = await response.json()
        console.error("Error:", errorData)
      }
    } catch (error) {
      console.error("An error occurred:", error)
    }
  }

  async function handleOrganizationNamechange(value) {
    console.log(`New organization name: ${value}`)
    setNewOrgName(value)
  }

  async function createOrganization() {
    console.log(`New organization ${JSON.stringify(newOrgName)} created`)
    try {
      const token = await getToken()
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/organization`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: newOrgName,
          }),
        }
      )

      if (response.ok) {
        console.log("Request was successful")
        // Refetch the organizations and organizationRulesets
        const organizations = await fetchData("organizations")
        const rulesets = await fetchData("rules/rulesets")

        setState(prevState => ({
          ...prevState,
          organizations,
          rulesets,
        }))

        // Reset newOrgName state so that the input box becomes empty
        setNewOrgName("")
      } else {
        const errorData = await response.json()
        console.error("Error:", errorData)
      }
    } catch (error) {
      console.error("An error occurred:", error)
    }
  }

  return (
    <>
      <Helmet>
        <title>Codaco Admin panel</title>
      </Helmet>
      <Wrapper>
        <PageHeading>Codaco Admin panel</PageHeading>

        {state.loading ? (
          <div>Loading items...</div>
        ) : state.error ? (
          <div>Error loading items.</div>
        ) : (
          <div>
            <SectionWrapper>
              <SectionTitle>Organizations</SectionTitle>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Number of Users</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {state.organizations.map(org => (
                    <tr key={org.id}>
                      <td>
                        <input
                          type="text"
                          value={
                            editingOrgId === org.id ? editedOrgName : org.name
                          }
                          disabled={editingOrgId !== org.id}
                          onChange={e => setEditedOrgName(e.target.value)}
                        />
                      </td>
                      <td>
                        {
                          state.users.filter(
                            user => user.organizationId === org.id
                          ).length
                        }
                      </td>
                      <td>
                        {org.name === "default" ? (
                          <span>Default</span>
                        ) : editingOrgId === org.id ? (
                          <button
                            onClick={() => {
                              updateOrganization(org.id, editedOrgName)
                              setEditingOrgId(null) // Stop editing
                            }}
                          >
                            Save
                          </button>
                        ) : (
                          <>
                            <button
                              onClick={() => {
                                setEditingOrgId(org.id)
                                setEditedOrgName(org.name)
                              }}
                            >
                              Edit
                            </button>
                            <button onClick={() => deleteOrganization(org.id)}>
                              Delete
                            </button>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <input
                        type="text"
                        placeholder="Enter new organization name"
                        value={newOrgName}
                        onChange={e =>
                          handleOrganizationNamechange(e.target.value)
                        }
                      />
                    </td>
                    <td></td>
                    <td>
                      <button onClick={createOrganization}>Save</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>Rulesets</SectionTitle>
              {state.rulesets.length > 0 &&
              state.organizations &&
              state.organizations.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>Rulesets \ organizations</th>
                      {state.organizations.map(org => (
                        <th key={org.id}>{org.name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {state.rulesets.map(ruleset => (
                      <tr key={ruleset.id}>
                        <td>{ruleset.name}</td>

                        {state.organizations.map(org => (
                          <td key={org.id}>
                            <input
                              type="checkbox"
                              checked={
                                ruleset.organizations &&
                                ruleset.organizations.some(
                                  rOrg => rOrg.organizationId === org.id
                                )
                              }
                              onChange={() => {
                                updateOrganizationRulesets(
                                  org.id,
                                  ruleset.id,
                                  !ruleset.organizations.some(
                                    rOrg => rOrg.organizationId === org.id
                                  )
                                )
                              }}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </SectionWrapper>
            <SectionWrapper>
              <SectionTitle>Users</SectionTitle>
              <table>
                <thead>
                  <tr>
                    {tableConfig.map(column => (
                      <th key={column.key}>{column.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {state.users.map(user => (
                    <tr key={user.id}>
                      {tableConfig.map(column => {
                        switch (column.type) {
                          case "text":
                            return <td key={column.key}>{user[column.key]}</td>
                          case "checkbox":
                            return (
                              <td key={column.key}>
                                <input
                                  type="checkbox"
                                  checked={!!user[column.key]}
                                  onChange={e =>
                                    updateUserValue(
                                      user,
                                      column.key,
                                      e.target.checked
                                    )
                                  }
                                />
                              </td>
                            )
                          case "dropdown":
                            const options = column.dynamicOptions
                              ? state[column.dynamicOptions]
                              : column.options
                            return (
                              <td key={column.key}>
                                <select
                                  value={
                                    user[column.key] === null
                                      ? "null"
                                      : user[column.key]
                                  }
                                  onChange={e =>
                                    updateUserValue(
                                      user,
                                      column.key,
                                      e.target.value === "null"
                                        ? null
                                        : e.target.value
                                    )
                                  }
                                  // Check if the current row's user ID matches the logged-in user's ID and if the column key is 'role'
                                  disabled={
                                    currentActiveUser?.id === user.id &&
                                    column.key === "role"
                                  }
                                >
                                  {user[column.key] === null && (
                                    <option value="null">Not assigned</option>
                                  )}

                                  {options.map(option => (
                                    <option
                                      key={option.id || option}
                                      value={option.id || option}
                                    >
                                      {option.name || option}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            )

                          default:
                            return null
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </SectionWrapper>
          </div>
        )}
      </Wrapper>
    </>
  )
}

export default AdminPage

const Wrapper = styled.div`
  // min-width: 75%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
`

const SectionWrapper = styled.div`
  margin: 20px 10px;
  padding: 10px;
  background: #ddd;
  border-radius: 4px;
`

const SectionTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
`
